import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import * as CONST from "../../components/const";

const kv_h = "../../images/business/kv_h.png";
const kv_v = "../../images/business/kv_v.png";
// const bg = "../../images/business/business_org.png";
const bg_h = "../../images/business/bg_h.png";
const bg_v = "../../images/business/bg_v.png";
const img1 = "../../images/business/img1.png";
const img2 = "../../images/business/img2.png";
const img3 = "../../images/business/img3.jpg";
const img4 = "../../images/business/img4.png";
const img5 = "../../images/business/img5.png";

function IndexPage() {
  const STATIC_IMAGE_PLACEHOLDER = "none"; // blurred tracedSVG none

  return (
    <Layout>
      <SEO
        keywords={[`IT`, `ネットワーク`, `開発`, `東京`]}
        title="Business"
      />

      <section className={CONST.SECTION_AREA_CLASS + " xl:pb-28"}>
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.BUSINESS_BACK_GROUND_PATTERN_CLASS} src={bg_h} />
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.BUSINESS_BACK_GROUND_PATTERN_CLASS} src={bg_v} />
        <div className={CONST.KEY_VISUAL_OFFSET_TITLE_AREA_CLASS + " xl:text-white"}>
          <p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Business content <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>事業内容</span></p>
        </div>

        <div className={CONST.KEY_VISUAL_LEFT_IMG_AREA_CLASS}>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_h} />
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_v} />
        </div>
        <div className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_OFFSET_CLASS}></div>
        <div data-aos="fade-left" className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_CLASS + " xl:pt-10 2xl:pt-10 3xl:pt-10 tracking-wider sm:leading-11 "}>
          <div >
            <h1 className={CONST.H1_CLASS + " font-light sm:leading-tight"}>
              <span className="">お客様のための<br></br>ＩＴサービス</span>
            </h1>
            <p>情報セキュリティサービス事業</p>
            <p>システム運用事業</p>
            <p>ＩＴ製品販売事業</p>
            <p>ＩＴコンサルティング事業</p>
            <p>コンピュータソフトウェアの開発事業</p>
          </div>
        </div>
      </section>

      {/* <section className={CONST.SECTION_P_AREA_CLASS + " mt-10 xl:-mt-20 3xl:-mt-28"}>
        <h2 data-aos="fade-up" className={CONST.H2_CLASS + " z-20"}>開発事業</h2>
        <div className="mt-5 sm:mt-6 md:mt-8 xl:mt-20 grid grid-cols-1 xl:grid-cols-3 gap-10 xl:gap-20 z-30">
          <div data-aos="fade-up">
            <h6 className={CONST.H6_CLASS}>システム開発支援</h6>
            <p>大規模な基幹業務や業務システムの開発を得意とします。チーム体制でご提案が可能です。</p>
            <p>&lt;得意な業務&gt;</p>
            <p>航空運輸、バンキング、証券、カード・与信、食品製造業、流通POS、など</p>
            <p>&lt;得意な技術&gt;</p>
            <p>Java、.NET、汎用機、など</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="200">
            <h6 className={CONST.H6_CLASS}>受託システム開発</h6>
            <p>中・小規模なシステムをターゲットとしてオンプレミスからやクラウドまでご対応が可能です。</p>
            <p>また、ユーザライクなＩ／Ｆ開発も得意としています。</p>
            <p>プライム案件を数多く手掛けており、これは当社の技術力とお客様からの信頼の高さの証です。</p>
            <p>&lt;得意な業務&gt;</p>
            <p>メーカー向け、コンシューマー向け、など</p>
            <p>&lt;得意な技術&gt;</p>
            <p>Java、PHP、VC++、C#、など</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="300">
            <h6 className={CONST.H6_CLASS}>ソリューション提供</h6>
            <p>Webコンサルティング/CMS設計/構築/運用/保守/SEO分析・対策など、Webに関する全てをワンストップでサービス提供いたします。</p>
            <p>さらにスマートフォンも組み合わせて、独創的なUI開発にも対応可能です。</p>
            <p>&lt;得意な業務&gt;</p>
            <p>ECサイト構築とサービス提供、SNSサイト構築とサービス提供、など</p>
            <p>&lt;得意な技術&gt;</p>
            <p>オープンソースCMS(WordPress,EC-CUBE)、Android、iOS、Java、PHP、Objective-C、C#、Facebook/Twitter連携、など</p>
          </div>
        </div>
      </section> */}

      <section data-aos="fade-up" className={CONST.SECTION_AREA_CLASS}>
        <div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>情報セキュリティサービス事業</h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img1} />
        </div>
        <div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS + " 3xl:-mt-20"}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>情報セキュリティサービス事業</h2>
            <p>お客様の情報セキュリティの悩みを解決致します。</p>
            <p>詳しいサービス内容は<a href = "https://www.horizon-esprit.co.jp/security-service/"><font color ="#4169e1"><u>こちら</u></font></a></p>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_AREA_REVERSE_CLASS}>
        <div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>システム運用事業</h2>
            <p>お客様のＩＴシステムの全てをサポート致します。</p>
          </div>
        </div>
        <div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}><span className="inline-block">ＩＴコンサルティング事業</span></h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img2} />
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_AREA_CLASS}>
        <div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>ＩＴ製品販売事業</h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img3} />
        </div>
        <div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS + " 3xl:-mt-20"}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>ＩＴ製品販売事業</h2>
            <p>お客様のビジネスをより便利にするＩＴ製品を販売致します。</p><br></br>
            <p>〇取り扱い商品</p>
            <p>・Microsoft 365</p>
          </div>
        </div>
      </section>
      
      <section data-aos="fade-up" className={CONST.SECTION_AREA_REVERSE_CLASS}>
        <div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>ＩＴコンサルティング事業</h2>
            <p>お客様のビジネスに対して適切なご提案を致します。</p>
          </div>
        </div>
        <div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}><span className="inline-block">ＩＴコンサルティング事業</span></h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img4} />
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_AREA_CLASS}>
        <div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>コンピュータソフトウェアの開発事業</h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img5} />
        </div>
        <div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS + " 3xl:-mt-20"}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>コンピュータソフトウェアの開発事業</h2>
            <p>お客様に合った様々な種類のソフトウェア開発を提供致します。</p>
            <p>大規模案件への参画や中小規模の受託案件の実績多数。</p>
            <p>得意分野はWEBシステム開発(Java, PHP, C#, Pythonなど)。</p>
          </div>
        </div>
      </section>


      
      

    </Layout >
  );
}

export default IndexPage;
